<template>
  <div class="container-fluid" v-if="mainMenu && mainMenu.active">
    <br/>
    <!--    {{ locale.hello }} language is: {{ $session.get("lang") }}-->
    <br/>
    <div class="row">
      <!--      <div class="col-md-2" style="text-align: start">-->
      <!--        <side-menu :menu-root="menuRoot"/>-->
      <!--      </div>-->
      <div class="col-md-12">
        <div
            class="categories"
            v-for="(category, index) in mainMenu.data.items"
            :key="index"
        >
          <div class="category row align-items-center" v-if="mainMenu.active">
            <div
                :class="
                `col-3 col-sm-2 col-md-2 ${
                  $session.get('direction') == 'ltr' ||
                  !$session.get('direction')
                    ? 'category-title'
                    : 'category-title-arabic'
                }`
              "
                v-if="category.active"
            >
              <p>
                <img
                    onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                    :src="category.iconUrl"
                    :class="category.tintable ? 'img-white' : ''"
                    :alt="category.icon"
                    width="60px"
                />
              </p>
              <p>{{ category.label }}</p>
            </div>
            <div class="col-9 col-sm-10 col-md-10">
              <div class="items">
                <slick ref="slick" :options="slickOptions">
                  <div
                      v-for="(item, index) in category.data.items"
                      :key="index"
                  >
                    <div class="item" v-if="item">
                      <!-- debut of menu items by type -->
                      <div class="item" v-if="item && item.type == 'voucher'">
                        <p>
                          <router-link
                              :to="{
                              name: 'Voucher',
                              params: { voucher: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">
                          {{ item.label }}
                        </p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'carfax'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: 'Carfax',
                              params: { carfax: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }}</p>
                      </div>

                      <div class="item" v-else-if="item && item.type == 'app'">
                        <p>
                          <router-link
                              :to="{
                              name: 'SpecificApplication',
                              params: { application: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }}</p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'dynamicapp'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: 'DynamicApplication',
                              params: { application: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }}</p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'listing'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: 'SpecificListing',
                              params: { category: item, all: category },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">
                          {{ item.label }}
                        </p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'category'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: 'SpecificListing',
                              params: { category: item, all: category },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }}</p>
                      </div>

                      <div class="item" v-else-if="item && item.type == 'bill'">
                        <p>
                          <router-link
                              :to="{
                              name: 'SpecificBill',
                              params: { bill: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">
                          {{ item.label }}
                        </p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'intltopup'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: 'IntTopup',
                              params: { object: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }}</p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'parkmeterfine'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: 'Parkmeter',
                              params: { object: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">
                          {{ item.label }}
                        </p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'phone'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: '',
                              params: { object: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">
                          {{ item.label }} phone NOT DONE
                        </p>
                      </div>
                      <div class="item" v-else-if="item && item.type == 'qr'">
                        <p>
                          <router-link
                              :to="{
                              name: 'QR',
                              params: { object: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }}</p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'wholesale'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: 'WholeSale',
                              params: { object: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            /></router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }}</p>
                      </div>

                      <div class="item" v-else-if="item && item.type == 'link'">
                        <p>
                          <a :href="item.data.target" target="_blank">
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 100%"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </a>
                        </p>
                        <p style="font-size: 13px">
                          {{ item.label }}
                        </p>
                      </div>

                      <div class="item" v-else-if="item && item.type == 'web'">
                        <p>
                          <a :href="item.data.target" target="_blank">
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 100%"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </a>
                        </p>
                        <p style="font-size: 13px">
                          {{ item.label }}
                        </p>
                      </div>

                      <div class="item" v-else-if="item && item.type == 'call'">
                        <p>
                          <a :href="`tel:${item.data.target}`" target="_blank">
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 100%"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </a>
                        </p>

                        <p style="font-size: 13px">
                          {{ item.label }}
                        </p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'price'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: '',
                              params: { object: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }} price</p>
                      </div>

                      <div
                          class="item"
                          v-else-if="item && item.type == 'transfer'"
                      >
                        <p>
                          <router-link
                              :to="{
                              name: 'Transfer',
                              params: { transfer: item },
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }}</p>
                      </div>

                      <div class="item" v-else>
                        <p>
                          <router-link
                              :to="{
                              name: 'ComingSoon',
                            }"
                          >
                            <img
                                onerror="this.src = `http://futureuniversity.com/wp-content/uploads/sites/9/2015/02/default-placeholder-1024x1024-570x321.png`"
                                :src="item.iconUrl"
                                :alt="item.icon"
                                style="width: 70px"
                                :class="item.tintable ? `img-grey` : ``"
                            />
                          </router-link>
                        </p>
                        <p style="font-size: 13px">{{ item.label }}</p>
                      </div>

                      <!-- <p>{{ item.type }}</p> -->
                    </div>
                  </div>
                </slick>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/><br/><br/>
    <Loading :loading="loader"/>
  </div>
</template>

<script>
// @ is an alias to /src
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import firebase from "firebase";
import Slick from "vue-slick";
import Loading from "./Loading.vue";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "Products",
  components: {Slick, Loading},
  props: {
    mainMenu: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      menuRoot: null,
      locale: null,
      loader: false,
      start: false,
      // mainMenu: this.$session.get("menu"),

      slickOptions: {
        rtl: this.$session.get("direction") == "rtl" ? true : false,
        infinite: false,
        arrows: false,
        paddingLeft: "0px",
        paddingRight: "0px",
        marginRight: "0px",
        slidesToShow: 6,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 400,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  methods: {
    drag(e) {
      console.log(e);
    },
    setLoader(show) {
      this.loader = show;
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    logout() {
      this.setLoader(true);
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiResponse(result)) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
    getTopLeftMenu(result) {
      if (result.data.data.menu && result.data.data.menu.menuRoot) {
        // get updated
        let menuRootTemp = result.data.data.menu.menuRoot;
        if (menuRootTemp)
          for (let i = 0; i < menuRootTemp.data.items.length; i++) {
            if (!menuRootTemp.data.items[i].active) {
              delete menuRootTemp.data.items[i];
            }
            if (menuRootTemp.data.items[i].data.items) {
              for (
                  let j = 0;
                  j < menuRootTemp.data.items[i].data.items.length;
                  j++
              ) {
                if (!menuRootTemp.data.items[i].data.items[j]) {
                  delete menuRootTemp.data.items[i].data.items[j];
                }
              }
            }
          }
        console.log("menuroot: ", menuRootTemp);
        this.$session.set("menuRoot", menuRootTemp);
        this.menuRoot = menuRootTemp;
        console.log(result.data.data.menu.topupAccount);
        if (result.data.data.menu && result.data.data.menu.topupAccount) {
          this.topupObject = result.data.data.menu.topupAccount;
          this.$session.set("topupAccount", result.data.data.menu.topupAccount);
        } else {
          console.log("top-up: ", this.$session.get("topupAccount"));
          this.topupObject = this.$session.get("topupAccount");
        }
      } else {
        // take from cache
        this.menuRoot = this.$session.get("menuRoot");
        this.topupObject = this.$session.get("topupAccount");
      }
    },
    getMenu() {
      let serverVersion = 0;
      if (this.$session.get("serverversion")) {
        serverVersion = this.$session.get("serverversion");
      }
      if (this.$session.get("menuRoot")) {
        this.menuRoot = this.$session.get("menuRoot");
      }
      if (this.$session.get("menu")) {
        this.mainMenu = this.$session.get("menu");
        console.log("taking menu from cache");
        this.start = true;
      } else {
        this.setLoader(true);
      }
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/homepage`, {
            //body
            servicesVersion: this.$session.get("servicesversion"),
            serverVersion: serverVersion, //by default it's 0 then updated from response
          })
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiRes(result, {
              //body
              servicesVersion: this.$session.get("servicesversion"),
              serverVersion: serverVersion, //by default it's 0 then updated from response
            }, "account/homepage", "post")) {
              // ok
              this.getTopLeftMenu(result);
              console.log("getMenu", result);
              this.$session.set("me", result.headers);
              // default payment options
              // srver.defaultPayment and server.defaultPaymentId save them in session
              // top left menu there is an item of type default_payment it is a switch of value usertoggleValue == server.defaultPayment
              // if the user changes it change userToggleValue
              // every time we refresh we should check if the old server.defaultPaymentId with the new one
              // if server.defaultPaymentId mghayra && usertogglevalue == server.defaultPayment  reset user toggleValue = server.defaultPayment bool
              // else do nothing
              this.$session.set(
                  "defaultPayment",
                  result.data.data.server.defaultPayment
              );
              this.$session.set(
                  "defaultPaymentId",
                  result.data.data.server.defaultPaymentId
              );

              if (result.data.data.menu)
                this.$session.set(
                    "paymentOptions",
                    result.data.data.menu.paymentOptions
                );
              // end

              this.$session.set("profileImage", result.data.data.profileImage);
              if (result.data.data.menu) {
                // get updated
                this.$session.set("allMenus", result);
                let server = JSON.parse(result.config.data);
                if (server) {
                  this.$session.set("server", server);
                } else {
                  server = this.$session.get("server");
                }
                this.$session.set("serverversion", server.serverVersion);
                this.mainMenu = result.data.data.menu.root;
                this.$session.set("menu", this.mainMenu);
                // for (let i = 0; i < this.mainMenu.data.items.length; i++) {
                //   // console.log(this.mainMenu.data.items[i].type);
                //   if (!this.mainMenu.data.items[i].active) {
                //     this.mainMenu.data.items.splice(i, 1);
                //   } else {
                //     for (
                //       let j = 0;
                //       j < this.mainMenu.data.items[i].data.items.length;
                //       j++
                //     ) {
                //       // console.log(this.mainMenu.data.items[i].data.items[j].type);
                //       if (!this.mainMenu.data.items[i].data.items[j].active) {
                //         this.mainMenu.data.items[i].data.items.splice(j, 1);
                //         j--;
                //       }
                //     }
                //   }
                // }
                this.$session.set("currency", result.data.data.server.currency);
                this.$session.set("menuRoot", result.data.data.menu.menuRoot);
              } else {
                // take from cache
                this.mainMenu = this.$session.get("menu");
              }
              if (result.data.data.announcement) {
                let announcement = result.data.data.announcement;
                console.log("announcment", announcement);
                const h = this.$createElement;
                let him = this;
                this.$info({
                  title: announcement.titleEnglish,
                  content: h("div", {}, [
                    h("p", `${announcement.messageEnglish}`),
                  ]),
                  okText: announcement.okEnlgish,
                  cancelText: announcement.viewEnlgish,

                  onOk() {
                    if (announcement.link) {
                      him.setLoader(true);
                      him.getAxios().post(`${him.$session.get("baseURI")}announcement/readAnnouncement?announcementId=${announcement.id}`, "").then((result) => {
                        this.setLoader(false);
                        if (him.checkApiRes(result, "", `announcement/readAnnouncement?announcementId=${announcement.id}`, "post")) {
                          // ok
                          console.log("announcment", result);
                          window.location.href = announcement.link;
                        }
                      });
                    }
                  },
                  onCancel() {
                  },
                });
              }

              console.log("menu: ", this.mainMenu);
              this.start = true;
            }
          });
    },

    success(title, message) {
      this.$success({
        title: title,
        // JSX support
        content: (
            <div>
              <p> {message} </p>
            </div>
        ),
      });
    },

    error(title, message) {
      this.$error({
        title: title,
        content: message,
      });
    },

    checkCCPayment() {
      // takes a query string to get info from session used
      let sessionKey = this.getParameterByName("payment");
      console.log(sessionKey);
      if (sessionKey) {
        // if url containes the payment query to get the session data
        console.log(
            "data from session: ",
            this.$session.get(sessionKey) || "topup already used"
        );
        let values = this.$session.get(sessionKey); // values that should be submitted to the api taken from session
        if (values) {
          // if session return a json to post
          values.fetchPrice = false;
          this.setLoader(true);
          this.getAxios()
              .post(
                  `${this.$session.get("baseURI")}account/topup/creditcard`,
                  values
              )
              .then((result) => {
                this.setLoader(false);
                if (this.checkApiRes(result, values, "account/topup/creditcard", "post")) {
                  console.log("success", result);
                  if (values.type == "voucher") {
                    //checking if there is the recipient phone it means that we should direct recharge
                    let apiEndpoint = "sale/voucher";
                    if (values.recipientMsisdn) {
                      apiEndpoint = "sale/recharge";
                      console.log("direct recharge values: ", values);
                    }
                    this.setLoader(true);
                    this.getAxios()
                        .post(
                            `${this.$session.get("baseURI")}${apiEndpoint}`,
                            //body
                            values
                        )
                        .then((result) => {
                          this.setLoader(false);
                          if (this.checkApiRes(result, values, apiEndpoint, "post")) {
                            console.log("success cc voucher", result);
                            this.$router.push({
                              name: "Voucher",
                              params: {sold: result},
                            });
                          } else {
                            console.error("error cc voucher", result);
                          }
                        });
                  } else if (values.type == "carfax") {
                    console.log("carfax CC vals: ", values);
                    this.$router.push({
                      name: "Carfax",
                      params: {old: values},
                    });
                  } else if (values.type == "transfer") {
                    console.log("transfer CC vals: ", values);
                    this.$router.push({
                      name: "Transfer",
                      params: {old: values},
                    });
                  } else if (values.type == "parkmeterfine") {
                    console.log("parkmeterfine CC vals: ", values);
                    this.$router.push({
                      name: "Parkmeter",
                      params: {old: values},
                    });
                  } else if (values.type == "phone") {
                    console.log("phone CC vals: ", values);
                    this.$router.push({
                      name: "Phone",
                      params: {old: values},
                    });
                  } else if (values.type == "bill") {
                    console.log("bill CC vals: ", values);
                    this.$router.push({
                      name: "SpecificBill",
                      params: {old: values},
                    });
                  } else if (values.type == "qr") {
                    console.log("bill qr vals: ", values);
                    this.$router.push({
                      name: "QR",
                      params: {old: values},
                    });
                  } else if (values.type == "wholesale") {
                    console.log("wholesale vals: ", values);
                    this.$router.push({
                      name: "WholeSale",
                      params: {old: values},
                    });
                  } else {
                    this.success(
                        result.data.dialog.title,
                        result.data.dialog.message
                    );
                  }

                  //
                } else {
                  console.log("error cc payment", result);
                  this.error(
                      result.data.dialog.title,
                      result.data.dialog.message
                  );
                }
                // removing session key in order not to show this dialogue multiple times
                this.$session.remove(sessionKey);
              });
        }
      }
    },
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error(`error in (${result.config.url})`, result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    getParameterByName(name) {
      let url = window.location.href;
      name = name.replace(/[[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },
  },
  created: function () {
    this.checkLocal();
    // this.getMenu();
    this.checkCCPayment();
  },
};
</script>

<style lang="scss">
.img-grey {
  filter: grayscale(1);
}

.slick-track {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.img-white {
  filter: brightness(0) invert(1);
}
</style>

<style lang="scss" scoped>
.img-grey {
  filter: grayscale(1);
}

.img-white {
  filter: brightness(0) invert(1);
}

.categories {
  .category {
    margin-bottom: 10px;

    .category-title {
      p {
        margin-top: 7px;
        margin-bottom: 0px;
      }

      background: #e62946;
      color: white;
      border-top-right-radius: 45px;
      border-bottom-right-radius: 45px;
      padding: 0.25% 1.5% 0.25% 1%;
      // width: fit-content !important;
      // max-width: fit-content;
      // min-width: fit-content;
      font-size: 60%;
      width: 50px;
      height: 100px;
      max-width: 120px;
      width: 80px;
      min-width: 70px;

      img {
        width: 50px;
        height: 50px;
        object-fit: scale-down;
      }
    }

    .category-title-arabic {
      p {
        margin-top: 7px;
        margin-bottom: 0px;
      }

      background: #e62946;
      color: white;
      border-top-left-radius: 45px;
      border-bottom-left-radius: 45px;
      padding: 0.25% 1.5% 0.25% 1%;
      // width: fit-content !important;
      // max-width: fit-content;
      // min-width: fit-content;
      font-size: 60%;
      width: 50px;
      height: 100px;
      max-width: 120px;
      width: 80px;
      min-width: 80px;

      img {
        width: 50px;
        height: 50px;
        object-fit: scale-down;
      }
    }

    .items {
      border-bottom: 1px solid black;

      a {
        text-decoration: none;
        color: black;
      }

      // .item {
      //   margin-right: 10px !important;
      //   height: 12vh;
      //   p {
      //     position: relative;
      //     bottom: 0;
      //   }
      //   img {
      //     padding: 5px;
      //     margin: auto;
      //     object-fit: scale-down;
      //     height: 80%;
      //   }
      // }
      .item {
        margin-right: 10px !important;
        height: 12vh;

        img {
          cursor: pointer;
          padding: 5px;
          margin: auto;
          object-fit: scale-down;
          height: 60px;
        }
      }
    }
  }
}
</style>
