<template>
  <div class="container-fluid">
    <div
        class="row align-items-center d-flex justify-content-between"

    >
      <div class=" balance">
        <h5 v-if="balance">{{ balance }}</h5>
        <img v-else-if="loading"
             :src="require('../assets/loading.gif')" width="70px" alt=""/>
      </div>
      <div
          class=" balance"
          v-if="server && server.otherServers && server.otherServers.length > 0"
      >
        <div
            class="row "
            v-if="server.labels && server.labels['1'] && server.labels['2']"
        >
          <div class=" first_currency">
            <h5 v-if="balance">{{ server.labels[`${this.server.id}`] }}</h5>
          </div>
          <div class="switch">
            <a-switch
                v-if="balance"
                v-model="checked"
                @change="
                (checked) => {
                  switchServer(checked);
                }
              "
            />
          </div>
          <div class="second_currency">
            <h5 v-if="balance">{{ server.labels[`${this.server.otherServers[0].id}`] }}</h5>
          </div>
        </div>
      </div>
      <div
          class="profile"
          @contextmenu="
          (e) => {
            e.preventDefault();
            this.accountInfo();
          }
        "
      >
        <router-link
            :to="{
            name: 'CheckProfile',
          }"
        >
          <img
              class="navbar-brand"
              :src="require('../assets/ProfilePicturePlaceholder.png')"
              alt=""
              style="width: 70px; max-height: 70px;"
              ref="profilePicBalance"
          />
        </router-link>
      </div>
    </div>
<!--    <div class="row loading" v-if="loading">-->
<!--      <img :src="require('../assets/loading.gif')" width="100px" alt=""/>-->
<!--    </div>-->
  </div>
</template>

<script>
import "bootstrap-toggle/css/bootstrap-toggle.min.css";
import "bootstrap-toggle/js/bootstrap-toggle.min";
import axios from "axios";
import firebase from "firebase";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

// @ is an alias to /src
export default {
  name: "Balance",
  components: {},
  data() {
    return {
      locale: null,
      loading: false,
      checked: this.$session.get("lang") == "en" ? false : true,
      balance: null,
      currency: false,
      server: null,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") === "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    accountInfo() {
      let id = this.$session.get("userId");
      let name = this.$session.get("me").storename;
      let phone = this.$session.get("me").msisdn;
      let session = this.$session.get("sessionId");
      let device = this.$session.get("deviceid");
      let self = this;
      this.$confirm({
        title: "Account Info",
        content: (
            <div>
              <p>
                {self.locale.acc_accountId}: {id}{" "}
              </p>
              <p>
                {self.locale.acc_accountName}: {name}{" "}
              </p>
              <p>
                {self.locale.acc_phone}: {phone}
              </p>
              <p>
                {self.locale.acc_session}: {session}
              </p>
              <p>
                {self.locale.acc_device}: {device}
              </p>
              <p>
                {self.locale.acc_version}: {1000}
              </p>
              <p>{self.locale.acc_build}: BETA v1 </p>
            </div>
        ),
        okType: "danger",
        cancelType: "danger",

        cancelText: self.locale.cancel,
        onCancel() {
        },
        okText: self.locale.logout,
        onOk() {
          self.logout();
        },
      });
    },
    getProfilePic() {
      let self = this;
      let ax = axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
        responseType: "blob",
      });
      // either full to get a large image or thumb to get a thumbnail of it
      if (this.$session.get("profileImage")) {
        ax.get(
            `${this.$session.get("baseURI")}image/${this.$session.get("profileImage")}/thumb`)
            .then((response) => {
              const reader = new FileReader();
              reader.addEventListener(
                  "load",
                  function () {
                    // convert image file to base64 string
                    self.$refs.profilePicBalance.src = reader.result;
                  },
                  false
              );
              reader.readAsDataURL(response.data);
            });
      } else {
        try {
          this.$refs.profilePicBalance.src = require("../assets/ProfilePicturePlaceholder.png");
        } catch (e) {
          this.$refs.profilePicBalance.src = require("../assets/ProfilePicturePlaceholder.png");
        }
      }
    },
    showConfirm(title, message) {
      let self = this;
      this.$confirm({
        title: title,
        content: () => <div>{message}</div>,
        onOk() {
          self
              .getAxios()
              .post(`${self.$session.get("baseURI")}global/switch`, {
                id: self.server.otherServers[0].id,
                country: null,
              })
              .then((result) => {
                if (self.checkApiRes(result, {
                  id: self.server.otherServers[0].id,
                  country: null,
                }, "global/switch", "post")) {
                  // ok
                  console.log("server switch", result);
                  self.$session.set("baseURI", result.data.data.api);
                  self.$session.remove("menu");
                  window.location.href = "/";
                }
              });
        },
        onCancel() {
          self.checked = !self.checked;
        },
        class: "test",
      });
    },
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error("server switch error : ", result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    logout() {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            if (this.checkApiResponse(result)) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    switchServer(checked) {
      // id is the server id to be switched to
      // if this.check is false then id is 2 and going to 1 else id is 1 going to 2 ; id is this.server.id
      // his.server.otherServers[0].id using this because we are always changing to the other server
      console.log(checked, this.checked);
      if (this.server.dialogs[`${this.server.otherServers[0].id}`])
        this.showConfirm(
            "",
            this.server.dialogs[`${this.server.otherServers[0].id}`]
        );
    },
    getBalance() {
      this.loading = true;
      let serverVersion = 0;
      if (this.$session.get("serverversion")) {
        serverVersion = this.$session.get("serverversion");
      }
      if (this.$session.get("serverversion")) {
        serverVersion = this.$session.get("serverversion");
      }
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/homepage`, {
            //body
            servicesVersion: this.$session.get("servicesversion"),
            serverVersion: serverVersion, //by default it's 0 then updated from response
          })
          .then((result) => {
            if (this.checkApiRes(result, {
              //body
              servicesVersion: this.$session.get("servicesversion"),
              serverVersion: serverVersion, //by default it's 0 then updated from response
            }, "account/homepage", "post")) {
              // ok
              this.getProfilePic();

              this.loading = false;
              console.log("Balance", result);
              this.$session.set("currencyFormat", result.data.data.server.currencyFormat);
              this.balance = result.data.data.balance;
              this.server = result.data.data.server;
              console.log(this.balance);
            }
          });
    },
  },
  created: function () {
    this.checkLocal();
    this.getBalance();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .second_currency {
    h5 {
      margin-right: 20px;
    }
  }
  .balance {
    h5 {
      font-size: 13px;
      margin-top: 4px;
    }
  }
  .profile {
    display: none;
  }
}

.switch {
  button {
    margin-top: 20px;
  }
}

.first_currency {
  text-align: end;
}

.second_currency {
  text-align: start;
}

.balance {
  text-align: start;

  h5 {
    font-weight: 700;
    padding-top: 20px;
  }

  .checkbox {
    padding-top: 15px;
    text-align: center;
  }
}

.toggle {
  text-align: end;

  h5 {
    padding-top: 5%;
  }
}
</style>
