var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row align-items-center d-flex justify-content-between"},[_c('div',{staticClass:" balance"},[(_vm.balance)?_c('h5',[_vm._v(_vm._s(_vm.balance))]):(_vm.loading)?_c('img',{attrs:{"src":require('../assets/loading.gif'),"width":"70px","alt":""}}):_vm._e()]),(_vm.server && _vm.server.otherServers && _vm.server.otherServers.length > 0)?_c('div',{staticClass:" balance"},[(_vm.server.labels && _vm.server.labels['1'] && _vm.server.labels['2'])?_c('div',{staticClass:"row "},[_c('div',{staticClass:" first_currency"},[(_vm.balance)?_c('h5',[_vm._v(_vm._s(_vm.server.labels[("" + (this.server.id))]))]):_vm._e()]),_c('div',{staticClass:"switch"},[(_vm.balance)?_c('a-switch',{on:{"change":function (checked) {
                  _vm.switchServer(checked);
                }},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}):_vm._e()],1),_c('div',{staticClass:"second_currency"},[(_vm.balance)?_c('h5',[_vm._v(_vm._s(_vm.server.labels[("" + (this.server.otherServers[0].id))]))]):_vm._e()])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"profile",on:{"contextmenu":function (e) {
            e.preventDefault();
            this$1.accountInfo();
          }}},[_c('router-link',{attrs:{"to":{
            name: 'CheckProfile',
          }}},[_c('img',{ref:"profilePicBalance",staticClass:"navbar-brand",staticStyle:{"width":"70px","max-height":"70px"},attrs:{"src":require('../assets/ProfilePicturePlaceholder.png'),"alt":""}})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }