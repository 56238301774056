<template>
  <div style="overflow: hidden">
    <Navbar
      :menuRoot="menuRoot"
      style="display: none"
      class="show-on-phone"
      v-if="false"
    />
    <div class="container-fluid">
      <div ref="homePageMother" class="row sidebar-expanded">
        <div class=" " id="sidebar">
          <side-menu
            @collapse="collapse($event)"
            :topupFlag="$route.params.topup"
            :menu-root="menuRoot"
            :topupObject="topupObject"
            v-if="menuRoot"
          />
        </div>
        <div class="" id="menu_products">
          <Balance />
          <Products
            :main-menu="mainMenu || this.$session.get('menuRoot')"
            v-if="mainMenu || this.$session.get('menuRoot')"
          />
        </div>
      </div>
    </div>
    <loading :loading="loading" />
  </div>
</template>
<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar";
import Balance from "@/components/Balance";
import Products from "@/components/Products";
import axios from "axios";
import firebase from "firebase";

export default {
  name: "Home",
  components: {
    Navbar,
    Balance,
    Products,
  },
  data() {
    return {
      loading: false,
      topupObject: null,
      menuRoot: this.$session.get("menuRoot"),
      mainMenu: null,
    };
  },
  methods: {
    collapse(ev) {
      console.log(ev);
      let mother = this.$refs.homePageMother;
      if (mother.classList.contains("sidebar-expanded")) {
        mother.classList.remove("sidebar-expanded");
        mother.classList.add(ev);
      } else if (mother.classList.contains("sidebar-collapsed")) {
        mother.classList.remove("sidebar-collapsed");
        mother.classList.add(ev);
      }
    },
    setLoader(show) {
      this.loading = show;
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    getTopLeftMenu(result) {
      if (result.data.data.menu && result.data.data.menu.menuRoot) {
        // get updated
        let menuRootTemp = result.data.data.menu.menuRoot;
        if (menuRootTemp)
          for (let i = 0; i < menuRootTemp.data.items.length; i++) {
            if (!menuRootTemp.data.items[i].active) {
              delete menuRootTemp.data.items[i];
            }
            if (menuRootTemp.data.items[i].data.items) {
              for (
                let j = 0;
                j < menuRootTemp.data.items[i].data.items.length;
                j++
              ) {
                if (!menuRootTemp.data.items[i].data.items[j]) {
                  delete menuRootTemp.data.items[i].data.items[j];
                }
              }
            }
          }
        console.log("menuroot: ", menuRootTemp);
        this.$session.set("menuRoot", menuRootTemp);
        this.menuRoot = menuRootTemp;
        console.log(result.data.data.menu.topupAccount);
        if (result.data.data.menu && result.data.data.menu.topupAccount) {
          this.topupObject = result.data.data.menu.topupAccount;
          this.$session.set("topupAccount", result.data.data.menu.topupAccount);
        } else {
          console.log("top-up: ", this.$session.get("topupAccount"));
          this.topupObject = this.$session.get("topupAccount");
        }
      } else {
        // take from cache
        this.menuRoot = this.$session.get("menuRoot");
        this.topupObject = this.$session.get("topupAccount");
      }
    },
    getMenu() {
      let serverVersion = 0;
      if (this.$session.get("serverversion")) {
        serverVersion = this.$session.get("serverversion");
      }
      if (this.$session.get("menuRoot")) {
        this.menuRoot = this.$session.get("menuRoot");
      }
      if (this.$session.get("topupAccount")) {
        this.topupObject = this.$session.get("topupAccount");
      }
      if (this.$session.get("menu")) {
        this.mainMenu = this.$session.get("menu");
        console.log("taking menu from cache");
        this.start = true;
      } else {
        this.setLoader(true);
      }
      if (this.$session.get("baseURI")) {
        this.getAxios()
          .post(`${this.$session.get("baseURI")}account/homepage`, {
            //body
            servicesVersion: this.$session.get("servicesversion"),
            serverVersion: serverVersion, //by default it's 0 then updated from response
          })
          .then((result) => {
            this.setLoader(false);
            if (
              this.checkApiRes(
                result,
                {
                  servicesVersion: this.$session.get("servicesversion"),
                  serverVersion: serverVersion,
                },
                "account/homepage",
                "post"
              )
            ) {
              // ok
              this.getTopLeftMenu(result);
              console.log("getMenu", result);
              this.$session.set("me", result.headers);
              // default payment options
              // srver.defaultPayment and server.defaultPaymentId save them in session
              // top left menu there is an item of type default_payment it is a switch of value usertoggleValue == server.defaultPayment
              // if the user changes it change userToggleValue
              // every time we refresh we should check if the old server.defaultPaymentId with the new one
              // if server.defaultPaymentId mghayra && usertogglevalue == server.defaultPayment  reset user toggleValue = server.defaultPayment bool
              // else do nothing
              this.$session.set(
                "defaultPayment",
                result.data.data.server.defaultPayment
              );
              this.$session.set(
                "defaultPaymentId",
                result.data.data.server.defaultPaymentId
              );

              if (result.data.data.menu)
                this.$session.set(
                  "paymentOptions",
                  result.data.data.menu.paymentOptions
                );
              // end

              this.$session.set("profileImage", result.data.data.profileImage);
              if (result.data.data.menu) {
                // get updated
                this.$session.set("allMenus", result);
                let server = JSON.parse(result.config.data);
                if (server) {
                  this.$session.set("server", server);
                } else {
                  server = this.$session.get("server");
                }
                this.$session.set("serverversion", server.serverVersion);
                this.mainMenu = result.data.data.menu.root;
                this.$session.set("menu", this.mainMenu);
                // for (let i = 0; i < this.mainMenu.data.items.length; i++) {
                //   // console.log(this.mainMenu.data.items[i].type);
                //   if (!this.mainMenu.data.items[i].active) {
                //     this.mainMenu.data.items.splice(i, 1);
                //   } else {
                //     for (
                //       let j = 0;
                //       j < this.mainMenu.data.items[i].data.items.length;
                //       j++
                //     ) {
                //       // console.log(this.mainMenu.data.items[i].data.items[j].type);
                //       if (!this.mainMenu.data.items[i].data.items[j].active) {
                //         this.mainMenu.data.items[i].data.items.splice(j, 1);
                //         j--;
                //       }
                //     }
                //   }
                // }
                this.$session.set("currency", result.data.data.server.currency);
                this.$session.set("menuRoot", result.data.data.menu.menuRoot);
              } else {
                // take from cache
                console.warn("taking menu from cache");
                this.mainMenu = this.$session.get("menu");
              }
              if (result.data.data.announcement) {
                let announcement = result.data.data.announcement;
                console.log("announcment", announcement);
                const h = this.$createElement;
                let him = this;
                this.$info({
                  title: announcement.titleEnglish,
                  content: h("div", {}, [
                    h("p", `${announcement.messageEnglish}`),
                  ]),
                  okText: announcement.okEnlgish,
                  cancelText: announcement.viewEnlgish,

                  onOk() {
                    if (announcement.link) {
                      him.setLoader(true);
                      him
                        .getAxios()
                        .post(
                          `${him.$session.get(
                            "baseURI"
                          )}announcement/readAnnouncement?announcementId=${
                            announcement.id
                          }`,
                          ""
                        )
                        .then((result) => {
                          him.setLoader(false);
                          if (
                            him.checkApiRes(
                              result,
                              null,
                              `announcement/readAnnouncement?announcementId=${announcement.id}`,
                              "post"
                            )
                          ) {
                            // ok
                            console.log("announcment", result);
                            window.location.href = announcement.link;
                          }
                        });
                    }
                  },
                  onCancel() {},
                });
              }

              console.log("menu: ", this.mainMenu);
            }
          });
      } else {
        this.$message.warn("error occurred");
        this.$session.clear();
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.$router.replace("/login").catch(() => {});
            // Sign-out successful.
          })
          .catch((error) => {
            console.log(error);
            // An error happened.
          });
      }
    },
  },
  beforeMount() {
    this.getMenu();
  },
};
</script>

<style lang="scss">
.sidebar-collapsed {
  #menu_products {
    width: 95%;
  }

  #sidebar {
    width: 5%;
  }
}

.sidebar-expanded {
  #menu_products {
    width: 83.4% !important;
  }

  #sidebar {
    width: 16.6% !important;
  }
}

.show-on-phone {
  display: none;
}

@media only screen and (max-width: 600px) {
  .show-on-phone {
    display: initial;
  }
}

@media only screen and (max-width: 1600px) {
  @media only screen and (max-width: 1500px) {
    .sidebar-collapsed {
      #menu_products {
        width: 94%;
      }

      #sidebar {
        width: 6%;
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    .sidebar-collapsed {
      #menu_products {
        width: 93%;
      }

      #sidebar {
        width: 7%;
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .sidebar-collapsed {
      #menu_products {
        width: 90%;
      }

      #sidebar {
        width: 9%;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    .sidebar-collapsed {
      #menu_products {
        width: 85%;
      }

      #sidebar {
        width: 10%;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .sidebar-collapsed {
      #menu_products {
        width: 85%;
      }

      #sidebar {
        width: 12%;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .sidebar-collapsed {
      #menu_products {
        width: 85%;
      }

      #sidebar {
        width: 15%;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .sidebar-collapsed {
      #menu_products {
        width: 80%;
      }

      #sidebar {
        width: 18%;
      }
    }
  }
  @media only screen and (max-width: 440px) {
    .sidebar-collapsed {
      #menu_products {
        width: 78%;
      }

      #sidebar {
        width: 22%;
      }
    }
  }
}
</style>
