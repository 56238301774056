<template>
  <nav class="navbar navbar-expand-sm" style="max-height: 70px !important">
    <div class="container-fluid">
      <a-dropdown v-if="menuRoot">
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <img
              src="../assets/menuBurger.png"
              style="width: 20%"
              alt="options"
          />
          <!--          <a-icon type="setting" view-box="" />-->
        </a>
        <a-menu slot="overlay">
          <a-sub-menu
              v-for="(menuItem, index) in menuRoot.data.items"
              :key="index"
              :title="`${menuItem.label}`"
          >
            <a-menu-item
                v-for="(subMenuItem, subindex) in menuItem.data.items"
                :key="subindex"
            >
              <div
                  v-if="subMenuItem.type == 'call'"
                  class="d-flex justify-content-between"
              >
                {{ subMenuItem.label }}
                <a :href="`tel:${subMenuItem.data.target}`">{{
                    subMenuItem.data.label
                  }}</a>
              </div>
              <div
                  v-else-if="subMenuItem.type == 'whatsapp'"
                  class="d-flex justify-content-between"
              >
                <a
                    target="_blank"
                    :href="`https://api.whatsapp.com/send?phone=${subMenuItem.data.target}`"
                >{{ subMenuItem.label }}</a
                >
              </div>
              <div
                  v-else-if="subMenuItem.type == 'email'"
                  class="d-flex justify-content-between"
              >
                {{ subMenuItem.label }}
                <a :href="`mailto: ${subMenuItem.data.target}`">{{
                    subMenuItem.data.label
                  }}</a>
              </div>
              <div
                  v-else-if="subMenuItem.type == 'web'"
                  class="d-flex justify-content-between"
              >
                <a :href="subMenuItem.data.target">{{ subMenuItem.label }}</a>
              </div>
              <div
                  v-else-if="subMenuItem.type == 'link'"
                  class="d-flex justify-content-between"
              >
                {{ subMenuItem.label }}

                <a :href="subMenuItem.data.target">{{
                    subMenuItem.data.label
                  }}</a>
              </div>
              <div
                  v-else-if="subMenuItem.type == 'notifications'"
                  class="d-flex justify-content-between"
                  @click="getNotifications(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'servers'"
                  class="d-flex justify-content-between"
                  @click="getServers(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'devices'"
                  class="d-flex justify-content-between"
                  @click="getDevices(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'costs'"
                  class="d-flex justify-content-between"
                  @click="getCosts(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'topups'"
                  class="d-flex justify-content-between"
                  @click="goToCalendar(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'targets'"
                  class="d-flex justify-content-between"
                  @click="targets(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'balance'"
                  class="d-flex justify-content-between"
                  @click="getBalance(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'void'"
                  class="d-flex justify-content-between"
                  @click="getVoidItems(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'credit_cards'"
                  class="d-flex justify-content-between"
                  @click="getCreditCards(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'map'"
                  class="d-flex justify-content-between"
                  @click="goToMap(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'outlets'"
                  class="d-flex justify-content-between"
                  @click="checkOutlets(subMenuItem.data.id)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'server'"
                  class="d-flex justify-content-between"
                  @click="switchServer(subMenuItem.data)"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'topup'"
                  class="d-flex justify-content-between"
                  @click="topup()"
              >
                {{ subMenuItem.label }}
              </div>
              <div
                  v-else-if="subMenuItem.type == 'language'"
                  class="d-flex justify-content-between"
                  @click="changeLanguage()"
              >
                {{ subMenuItem.label }}
              </div>
              <div v-else class="d-flex justify-content-between">
                {{ subMenuItem.label }} type: {{ subMenuItem.type }}
              </div>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item @click="logout"> logout</a-menu-item>
        </a-menu>
      </a-dropdown>
      <div class="whish-logo">
        <router-link to="/Home">
          <img
              class="navbar-brand pulse"
              src="../assets/whish-white-logo_v2.png"
              alt="WHISH LOGO"
              style="width: 31%"
          />
        </router-link>
      </div>

      <div
          class="profile"
          @contextmenu="
          (e) => {
            e.preventDefault();
            this.accountInfo();
          }
        "
      >
        <router-link
            :to="{
            name: 'CheckProfile',
          }"
        >
          <img
              class="navbar-brand"
              :src="require('../assets/ProfilePicturePlaceholder.png')"
              alt=""
              style="width: 70px"
              ref="profilePic"
          />
        </router-link>
      </div>
      <!-- <button v-if="!menuRoot" @click="logout">force logout</button> -->
    </div>
    <a-modal
        v-if="notifications"
        v-model="visible"
        :title="locale.notifications"
        :footer="null"
    >
      <div v-if="notifications.length > 0">
        <!-- { "id": 778,
         "title": "Test Notification Title",
          "message": "This is a test notification body!",
           "read": false,
            "date": "22 minutes ago" } -->
        <div
            class="d-flex justify-content-between"
            v-for="notification in notifications"
            :key="notification.id"
        >
          <div>
            <h4>{{ notification.title || "" }}</h4>
            <p style="width=90%">{{ notification.message || "" }}</p>
          </div>
          <div>
            <p>{{ notification.date || "" }}</p>
          </div>
        </div>
      </div>
      <p v-else>{{ locale.nothingToSeeHere || "empty" }}</p>
    </a-modal>
    <a-modal
        v-if="servers"
        v-model="serversvisible"
        :title="locale.servers"
        :footer="null"
    >
      <div v-if="servers.servers.length > 0">
        <div class="" v-for="server in servers.servers" :key="server.id">
          <div
              class="d-flex justify-content-between margin-bot"
              v-for="(country, index) in server.countries"
              :key="index"
          >
            <div>
              <h5>
                <span>
                  <img
                      :src="
                      require(`../assets/flags/${country.toLowerCase()}.png`)
                    "
                      alt="icon"
                      style="width: 65px; height: 40px"
                  />
                </span>
                {{ country }}
              </h5>
            </div>
            <div>
              <button
                  @click="switchServerTo(server.id, server.api, country)"
                  class="whish-button"
              >
                {{ locale.switch }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <p v-else>{{ locale.nothingToSeeHere }}</p>
    </a-modal>
    <a-modal
        v-model="devicesModalVisible"
        v-if="devices"
        :title="locale.devices"
        :footer="null"
    >
      <div v-if="devices.length > 0">
        <div v-for="(device, index) in devices" :key="index" class="device">
          <div class="d-flex justify-content-between">
            <p>
              <strong>{{ locale.deviceid }} </strong>{{ device.sessionId }}
            </p>
            <p
                v-if="$session.get('sessionId') == device.sessionId"
                style="color: green"
            >
              {{ locale.currentDevice }}
            </p>
          </div>
          <p>
            <strong>{{ locale.version }} </strong>{{ device.version }}
          </p>
          <p>
            <strong>{{ locale.startdate }} </strong
            >{{ new Date(device.creationDate).toUTCString() }}
          </p>
          <p>
            <strong>{{ locale.brand }} </strong>{{ device.brand }}
          </p>
          <p>
            <strong>{{ locale.model }} </strong>{{ device.model }}
          </p>
          <p>
            <strong>{{ locale.sales7days }} </strong
            >{{ `${device.sum} ${$session.get("currency")}` }}
          </p>
          <div class="delete-button">
            <button @click="deleteDevice(device.sessionId)">
              {{ locale.delete }}
            </button>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal
        v-model="creditCardVisible"
        v-if="creditCardsObject"
        :title="locale.creditCards"
        :footer="null"
    >
      <div class="row d-flex justify-content-center" @click="addCard">
        <a-icon type="plus-circle"/>
      </div>

      <div
          class="creditCard row"
          v-for="card in creditCardsObject"
          :key="card.creditCardId"
      >
        <div class="col-2">
          <img
              v-if="card.type.toLowerCase() == 'visa'"
              :src="require(`../assets/ic_visa.png`)"
              alt=""
          />
          <img
              v-else-if="card.type.toLowerCase() == 'mastercard'"
              :src="require(`../assets/ic_mastercard.png`)"
              alt=""
          />
          <img v-else src="#" :alt="card.type"/>
        </div>
        <div class="col-8" style="text-align: start">
          <span>{{ card.labelName }}</span
          ><br/>
          <span>{{ card.number }}</span>
        </div>
        <div
            class="col-2 d-flex align-items-center"
            @click="removeCC(card.creditCardId)"
        >
          <a-icon type="minus-circle"/>
        </div>
      </div>
    </a-modal>
    <!-- outlets -->
    <a-modal
        v-if="outletsObject"
        v-model="outletsVisible"
        :title="locale.outlets"
        :footer="null"
    >
      <div
          class="row"
          v-for="(outlet, index) in outletsObject.data"
          :key="index"
          @click="collapseOutlets(index)"
      >
        <div class="col-3">
          <img
              :src="outlet.image"
              style="width: inherit"
              alt="image of the outlet"
          />
        </div>
        <div class="col-9">
          <h6>{{ outlet.title }}</h6>
          <p v-for="(detail, index2) in outlet.details" :key="`${index2}_`">
            {{ detail.label }} {{ detail.text }}
          </p>
          <hr/>
          <!-- the below div id is used to hide and show the wholw div on parent click -->
          <div :id="`${index}_collapse`" style="display: none">
            <div
                class="col-12"
                v-for="(item, index_item) in outlet.items"
                :key="`${index_item}__`"
            >
              <div class="">
                <h6>{{ item.title }}</h6>
                <p>{{ item.text }}</p>
                <p
                    v-for="(itemdetail, index) in item.details"
                    :key="`${index}_item_detail`"
                >
                  {{ itemdetail.label }} {{ itemdetail.text }}
                </p>
                <hr/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- end outlets -->
    <!-- topup  data.data.menu.topupAccount-->
    <a-modal
        v-if="topupObject"
        v-model="topupVisible"
        :title="topupObject.creditCardTopupData.title"
        :footer="null"
    >
      <a-form :form="form" @submit="handleSubmit" v-if="topupObject">
        <a-form-item :label="topupObject.creditCardTopupData.header">
          <a-input-number
              :formatter="
              (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            "
              :placeholder="topupObject.creditCardTopupData.hint"
              @change="topupAmountChanged"
              style="width: 100%"
              v-decorator="[
              'amount',
              {
                rules: [
                  {
                    required: true,
                    message: `${topupObject.creditCardTopupData.header}!`,
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="topupObject.creditCardTopupData.select">
          <a-select
              @change="handleTopupSelectChange"
              v-decorator="[
              'currencyId',
              {
                initialValue: topupObject.creditCardTopupData.defaultCurrencyId,
                rules: [
                  {
                    required: true,
                    message: `${topupObject.creditCardTopupData.select}!`,
                  },
                ],
              },
            ]"
          >
            <a-select-option
                :value="option.id"
                v-for="option in topupObject.creditCardTopupData.currencies"
                :key="option.id"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <p>
          {{ topupObject.creditCardTopupData.deductible }}
          {{ amount.currency }}
          {{
            (Number(amount.price) / Number(amount.addedValue))
                | numeral(amount.format)
          }}
          <br/>
          <small> {{ amount.note }} </small>
        </p>
        <a-form-item :wrapper-col="{ span: 12, offset: 9 }">
          <a-button type="danger" html-type="submit">
            {{ amount.submit }}
          </a-button>
        </a-form-item>
      </a-form>
      <div v-if="chooseCard">
        <div
            style="cursor: pointer"
            class="row d-flex justify-content-center"
            @click="showaddCard = !showaddCard"
        >
          <a-icon type="plus-circle" v-if="!showaddCard"/>
          <a-icon type="minus-circle" v-if="showaddCard"/>
        </div>
        <CreditCardPortable v-if="showaddCard"/>

        <div
            class="
            creditCard
            row
            ml-1
            mt-2
            mb-2
            card_custom
            d-flex
            align-items-center
            pointer
          "
            v-for="card in creditCardsObject"
            :key="card.creditCardId"
            @click="checkCVC(card)"
        >
          <div class="col-2">
            <img
                v-if="card.type.toLowerCase() == 'visa'"
                :src="require(`../assets/ic_visa.png`)"
                alt=""
            />
            <img
                v-else-if="card.type.toLowerCase() == 'mastercard'"
                :src="require(`../assets/ic_mastercard.png`)"
                alt=""
            />
            <img v-else src="#" :alt="card.type"/>
          </div>
          <div class="col-2 d-flex align-items-center"></div>
          <div class="col-8" style="text-align: start">
            <span>{{ card.labelName }}</span
            ><br/>
            <span>{{ card.number }}</span>
          </div>
        </div>
        <div class="otp" v-if="otpObject">
          <div id="lol"></div>
          <iframe
              referrerpolicy=""
              allow="payment"
              ref="iframetopup"
              src=""
              width="100%"
              frameborder="0"
              @load="continueCCPaymentAfter3DSecure($refs.iframetopup)"
          ></iframe>
        </div>
      </div>
    </a-modal>
    <a-modal
        v-model="cvcPrompt"
        v-if="cvcPrompObject"
        :title="cvcPrompObject.securityCodeLabel"
        @ok="selectCard(cvcPrompObject)"
        :okText="cvcPrompObject.securityCodeButton"
        :cancelText="locale.cancel"
        okType="danger"
    >
      <input
          v-model="cvc"
          :placeholder="cvcPrompObject.securityCodeHint"
          :type="cvcPrompObject.securityCodeInputType"
          required
      />
    </a-modal>

    <!-- end topup -->
  </nav>
</template>

<script>
import "bootstrap";
import axios from "axios";

// import 'bootstrap/dist/css/bootstrap.min.css'
import CreditCardPortable from "@/components/CreditCardPortable";
import firebase from "firebase";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "Navbar",
  components: {CreditCardPortable},
  props: {
    menuRoot: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      // topup
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "coordinated"}),
      topupObject: null,
      topupVisible: false,
      amount: {
        currency: "",
        price: 0,
        addedValue: 0,
        format: "0,0.00",
        note: "",
        submit: "",
      },
      showaddCard: false,
      chooseCard: false,
      otp_iframe_visible: false,
      otpObject: null,
      //cvc prompt for topup
      cvcPrompObject: null,
      cvcPrompt: false,
      cvc: null,
      // end topup
      outletsObject: null,
      outletsVisible: false,
      creditCardVisible: false,
      creditCardsObject: null,
      devicesModalVisible: false,
      devices: null,
      // menuRoot: null,
      visible: false,
      notifications: null,
      servers: null,
      serversvisible: false,
      locale: null,
    };
  },
  methods: {
    changeLanguage() {
      let title = this.locale.changeLanguage;
      let to;
      let self = this;
      console.log(this.$session.get("lang"));
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        // ar change to en
        to = "en";
      } else {
        // en change to ar
        to = "ar";
      }
      this.$confirm({
        title: title,
        content: () => <div></div>,
        onOk() {
          self.$session.set("lang", to);
          if (to == "ar") {
            self.$session.set("direction", "rtl");
          } else {
            self.$session.set("direction", "ltr");
          }
          location.reload();
        },
        okText: self.locale.continue,
        cancelText: self.locale.cancel,
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    // useless
    callback(val) {
      console.log(val);
    },
    // end useless
    success(title, message) {
      this.$success({
        title: title,
        // JSX support
        content: (
            <div>
              <p>{message}</p>
            </div>
        ),
      });
    },
    error(title, message) {
      this.$error({
        title: title,
        content: message,
      });
    },

    // topup functions
    continueCCPaymentAfter3DSecure(iframe) {
      let values = this.$session.get("topupValues");
      if (values) {
        if (
            window.location.origin + "/" ==
            iframe.contentWindow.location.href
        ) {
          values.fetchPrice = false;
          this.getAxios()
              .post(
                  `${this.$session.get("baseURI")}account/topup/creditcard`,
                  values
              )
              .then((result) => {
                this.topupVisible = false;
                if (this.checkApiRes(result, values, "account/topup/creditcard", "post")) {
                  console.log("top-up success", result);
                  this.success(
                      result.data.dialog.title,
                      result.data.dialog.message
                  );
                } else {
                  console.log("error cc payment", result);
                  this.error(
                      result.data.dialog.title,
                      result.data.dialog.message
                  );
                }
                // removing session key in order not to use same values multiple times
                this.$session.remove("topupValues");
              });
        }
      }
    },
    openCVCPrompt() {
      this.cvcPrompt = true;
      this.cvc = null;
    },
    closeCVCPrompt() {
      this.cvcPrompt = false;
    },
    handleTopupSelectChange(value) {
      console.log("value top up changed to ", value);
      this.updateAmount(value);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.getAllCreditCards(null);
        }
      });
    },
    toggleTopUpModal() {
      this.topupVisible = !this.topupVisible;
    },
    topup() {
      this.toggleTopUpModal();
      if (this.topupObject) {
        this.updateAmount(
            this.topupObject.creditCardTopupData.defaultCurrencyId
        );
      } else {
        this.updateAmount(
            this.$session.get("topupAccount").creditCardTopupData
                .defaultCurrencyId
        );
      }

      console.log(this.topupObject);
    },
    updateAmount(id) {
      this.topupObject.creditCardTopupData.currencies.forEach((option) => {
        if (option.id == id) {
          this.amount.currency = option.shortCode;
          this.amount.addedValue = option.multiplier;
          this.amount.format = option.format.replace("#", "0");
          this.amount.note = option.note;
          this.amount.submit = option.submit;
        }
      });
    },
    topupAmountChanged(e) {
      // console.log(e);
      this.amount.price = e;
    },
    accountInfo() {
      let id = this.$session.get("userId");
      let name = this.$session.get("me").storename;
      let phone = this.$session.get("me").msisdn;
      let session = this.$session.get("sessionId");
      let device = this.$session.get("deviceid");
      let self = this;
      this.$confirm({
        title: "Account Info",
        content: (
            <div>
              <p>
                {self.locale.acc_accountId}: {id}{" "}
              </p>
              <p>
                {self.locale.acc_accountName}: {name}{" "}
              </p>
              <p>
                {self.locale.acc_phone}: {phone}
              </p>
              <p>
                {self.locale.acc_session}: {session}
              </p>
              <p>
                {self.locale.acc_device}: {device}
              </p>
              <p>
                {self.locale.acc_version}: {1000}
              </p>
              <p>{self.locale.acc_build}: BETA v1 </p>
            </div>
        ),
        okType: "danger",
        cancelType: "danger",

        cancelText: self.locale.cancel,
        onCancel() {
        },
        okText: self.locale.logout,
        onOk() {
          self.logout();
        },
      });
    },
    checkCVC(card) {
      if (card.securityCodeRequired) {
        this.cvcPrompObject = card;
        console.log("opening cvc prompt");
        this.openCVCPrompt();
      } else if (this.card.securityCodeRequired == false) {
        console.log("doesnt require cvc prompt");

        this.selectCard(card);
      }
    },
    selectCard(card) {
      this.closeCVCPrompt();
      //used when clicked on the card in topup by credit card to cahrge on the card
      console.log(card);
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          values.fetchPrice = true;
          values.creditCardId = card.creditCardId;
          if (card.securityCodeRequired) {
            values.creditCardSecurityCode = this.cvc;
          }

          this.getAxios()
              .post(
                  `${this.$session.get("baseURI")}account/topup/creditcard`,
                  values
              )
              .then((result) => {
                if (!this.checkApiRes(result, values, "account/topup/creditcard", "post")) {
                  // error take care of in checkApiResponse
                } else {
                  // ok
                  console.log("creditCard topup request", result);
                  // if url data is not null open its htm lin a webview
                  let data = result.data.data;
                  if (data.urlData) {
                    // save the values bcz he page is going to reload and in products page we take the query from url and resend the api from there
                    this.showaddCard = false;
                    this.creditCardsObject = null;
                    this.otp_iframe_visible = true;
                    this.otpObject = data;
                    setTimeout(() => {
                      document.getElementById("lol").innerHTML =
                          data.urlData.html;
                    }, 500);
                    this.$refs.iframetopup.src = data.urlData.html;
                    values.price = data.price;
                    values.urlDataId = data.urlData.id;
                    this.$session.set(`topupValues`, values);
                    this.$session.set(`${data.urlData.callback}`, values);
                  } else {
                    // url data is null send the api and fnish it
                  }
                }
              });
        }
      });
    },
    // end topup functions
    // server switch confirm
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error("server switch error : ", result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    showConfirm(message, server) {
      let self = this;
      this.$confirm({
        title: "",
        content: () => <div>{message}</div>,
        okText: self.locale.ok,
        cancelText: self.locale.cancel,
        onOk() {
          self.getAxios().post(`${self.$session.get("baseURI")}global/switch`, {id: server.id, country: null})
              .then((result) => {
                if (this.checkApiRes(result, {id: server.id, country: null}, "global/switch", "post")) {
                  // ok
                  console.log("server switch", result);
                  self.$session.set("baseURI", result.data.data.api);
                  self.$session.remove("menu");
                  window.location.href = "/";
                }
              });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    switchServer(item) {
      console.log(item);
      if (item.dialog) {
        // prompt dialog before switch
        this.showConfirm(item.dialog, item.server);
      } else {
        // switch without prompt
        let server = item.server;
        this.getAxios().post(`${this.$session.get("baseURI")}global/switch`, {
          id: server.id,
          country: null,
        }).then((result) => {
          if (this.checkApiRes(result, {id: server.id, country: null,}, "global/switch", "post")) {
            // ok
            console.log("server switch", result);
            this.$session.set("baseURI", result.data.data.api);
            this.$session.remove("menu");
            window.location.href = "/";
          }
        });
      }
    },
    collapseOutlets(index) {
      if (
          document.getElementById(`${index}_collapse`).style.display == "none"
      ) {
        document.getElementById(`${index}_collapse`).style.display = "inherit";
      } else {
        document.getElementById(`${index}_collapse`).style.display = "none";
      }
    },
    toggleOutletsModal() {
      this.outletsVisible = !this.outletsVisible;
    },
    checkOutlets(id) {
      this.getAxios()
          .get(`${this.$session.get("baseURI")}data/outlets/${id}`)
          .then((result) => {
            if (this.checkApiRes(result, null, `data/outlets/${id}`, "get")) {
              // ok
              console.log("get outlets: ", result);
              this.outletsObject = result.data;
              this.toggleOutletsModal();
            }
          });
    },
    addCard() {
      this.$router.push({
        name: "CC",
      });
    },
    goToMap(id) {
      this.$router.push({
        name: "Map",
        params: {id: id},
      });
    },
    handleSubmitCC(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    removeCC(ccid) {
      console.log(ccid);
      let self = this;
      this.$confirm({
        title: self.locale.delete_cc,
        content: "",
        okText: self.locale.delete,
        cancelText: self.locale.cancel,
        okType: "danger",

        onOk() {
          self
              .getAxios()
              .post(`${self.$session.get("baseURI")}credit-card/delete/card`, {
                creditCardId: ccid,
              })
              .then((result) => {
                if (this.checkApiRes(result, {
                  creditCardId: ccid,
                }, "credit-card/delete/card", "post")) {
                  // ok
                  console.log("getCreditCrads: ", result);
                  self.toggleCreditCardModal();
                }
              });
        },
        onCancel() {
        },
      });
    },
    toggleCreditCardModal() {
      this.creditCardVisible = !this.creditCardVisible;
    },
    getCreditCards(id) {
      this.getAxios()
          .get(`${this.$session.get("baseURI")}credit-card/cards/${id}`)
          .then((result) => {
            if (this.checkApiRes(result, null, `credit-card/cards/${id}`, "get")) {
              // ok
              console.log("getCreditCrads: ", result);
              this.creditCardsObject = result.data.data;
              this.toggleCreditCardModal();
            }
          });
    },
    getAllCreditCards(id) {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}credit-card/cards`, {
            optionId: id,
          })
          .then((result) => {
            if (this.checkApiRes(result, {optionId: id,}, "credit-card/cards", "post")) {
              // ok
              console.log("getAllCreditCrads: ", result);
              this.creditCardsObject = result.data.data;
              this.chooseCard = true;
            }
          });
    },
    getBalance(id) {
      this.getAxios()
          .get(`${this.$session.get("baseURI")}account/balance/${id}`)
          .then((result) => {
            if (this.checkApiRes(result, null, `account/balance/${id}`, "get")) {
              // ok
              console.log("getBalance: ", result);
              const h = this.$createElement;
              let balances = [];
              let self = this;
              result.data.data.balances.forEach((bal) => {
                balances.push(h("p", `${bal.label}: ${bal.text}`));
              });
              this.$info({
                title: "",
                content: h("div", {}, balances),
                onOk() {
                },
                okText: self.locale.ok,
              });
            }
          });
    },
    getVoidItems(id) {
      this.getAxios()
          .get(`${this.$session.get("baseURI")}account/void/${id}`)
          .then((result) => {
            if (this.checkApiRes(result, null, `account/void/${id}`, "get")) {
              // ok
              console.log("getVoidItems: ", result);
              if (result.data.dialog && result.data.data.length == 0) {
                this.warning(
                    result.data.dialog.title,
                    result.data.dialog.message
                );
              } else {
                // show void items modal
                const h = this.$createElement;
                let denoms = [];
                result.data.data.forEach((denomination) => {
                  denoms.push(h("p", `${denomination.denominationName}`));
                  denoms.push(h("hr"));
                });
                this.$info({
                  title: "",
                  content: h("div", {}, denoms),
                  onOk() {
                  },
                  okText: self.locale.ok,
                });
              }
              // const h = this.$createElement;
              // let balances = [];
              // result.data.data.balances.forEach((bal) => {
              //   balances.push(h("p", `${bal.label}: ${bal.text}`));
              // });
              // this.$info({
              //   title: "",
              //   content: h("div", {}, balances),
              //   onOk() {},
              // });
            }
          });
    },

    warning(title, message) {
      let self = this;
      this.$warning({
        title: title,
        content: message,
        okText: self.locale.ok,
        cancelText: self.locale.cancel,
      });
    },

    goToCalendar(id) {
      this.$router.push({
        name: "Calendar",
        params: {id: id},
      });
    },
    targets(id) {
      this.$router.push({
        name: "Targets",
        params: {id: id},
      });
    },
    getCosts(id) {
      console.log(id);
      this.getAxios()
          .get(`${this.$session.get("baseURI")}data/costs/${id}`)
          .then((result) => {
            if (this.checkApiRes(result, null, `data/costs/${id}`, "get")) {
              // ok
              console.log("getCost: ", result);
              this.$router.push({
                name: "Costs",
                params: {data: result.data.data},
              });
            }
          });
    },
    deleteDevice(sessionId) {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: sessionId,
          })
          .then((result) => {
            if (this.checkApiRes(result, {sessionId: sessionId,}, "account/session/deactivate", "post")) {
              // ok
              console.log("delete Device: ", result);
              this.toggleDevicesModal();
            }
          });
    },
    toggleModal() {
      this.visible = !this.visible;
    },
    toggleServersModal() {
      this.serversvisible = !this.serversvisible;
    },
    toggleDevicesModal() {
      this.devicesModalVisible = !this.devicesModalVisible;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    getServers(id) {
      console.log(id);
      this.getAxios()
          .get(`${this.$session.get("baseURI")}global/servers/${id}`)
          .then((result) => {
            if (this.checkApiRes(result, null, `global/servers/${id}`, "get")) {
              // ok
              console.log("servers: ", result);
              this.servers = result.data.data;
              this.toggleServersModal();
            }
          });
    },
    getProfilePic() {
      let self = this;
      let ax = axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
        responseType: "blob",
      });
      // either full to get a large image or thumb to get a thumbnail of it
      if (this.$session.get("profileImage")) {
        ax.get(`${this.$session.get("baseURI")}image/${this.$session.get("profileImage")}/thumb`).then((response) => {
          console.log(response);
          // console.log(Buffer.from(response.data, "base64"));
          const reader = new FileReader();
          reader.addEventListener(
              "load",
              function () {
                // convert image file to base64 string
                self.$refs.profilePic.src = reader.result;
              },
              false
          );
          reader.readAsDataURL(response.data);
        });
      } else {
        this.$refs.profilePic.src = require("../assets/ProfilePicturePlaceholder.png");
      }
    },
    getNotifications(id) {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/notifications/${id}`, {
            id: 0,
          })
          .then((result) => {
            if (this.checkApiRes(result, {id: 0}, `account/notifications/${id}`, "post")) {
              // ok
              console.log("notifications: ", result);
              let notifications = result.data.data.notifications;
              this.notifications = notifications;
              this.toggleModal();
            }
          });
    },
    switchServerTo(id, api, country) {
      if (id && api && country) {
        console.log(id, api, country);
        this.getAxios()
            .post(`${this.$session.get("baseURI")}global/switch`, {
              id: id,
              country: country,
            })
            .then((result) => {
              if (result.headers.sessioncounter) {
                this.$session.set(
                    "sessionCounter",
                    result.headers.sessioncounter
                );
              }
              if (!result.data.status) {
                // error
                console.log("switch server error ", result);
                this.$message.error(result.data.dialog.message || "Error");
                console.log("parkmeter error payment: ", result);
                if (result.data.code === "auth.session_expired") {
                  // log user out
                  this.logout();
                }
              } else {
                // ok
                console.log("server switch", result);
                this.$session.set("baseURI", result.data.data.api);
                window.location.href = "/";
              }
            });
      } else {
        this.$message.error("An error occured please try to again later.");
      }
    },
    getDevices(id) {
      console.log(id);
      this.getAxios()
          .get(`${this.$session.get("baseURI")}account/sessions/${id}`)
          .then((result) => {
            if (result.headers.sessioncounter) {
              this.$session.set("sessionCounter", result.headers.sessioncounter);
            }
            if (!result.data.status) {
              // error
              console.log("error", result);
              this.$message.error(result.data.dialog.message);
              if (result.data.code === "auth.session_expired") {
                // log user out
                this.logout();
              }
            } else {
              // ok
              console.log("devices: ", result);
              this.devices = result.data.data;
              this.toggleDevicesModal();
            }
          });
    },
    logout() {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            console.log(this.checkApiResponse(result));
            if (this.checkApiResponse(result)) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");
                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    getMenuRoot() {
      let serverVersion = 0;
      if (this.$session.get("serverversion")) {
        serverVersion = this.$session.get("serverversion");
      }

      if (this.$session.get("menuRoot")) {
        this.menuRoot = this.$session.get("menuRoot");
      }

      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/homepage`, {
            //body
            servicesVersion: this.$session.get("servicesversion"),
            serverVersion: serverVersion, //by default it's 0 then updated from response
          })
          .then((result) => {
            if (result.headers.sessioncounter) {
              this.$session.set("sessionCounter", result.headers.sessioncounter);
            }
            if (!result.data.status) {
              // error
              // this.$message.error(result.data.dialog.message);
              if (result.data.code === "auth.session_expired") {
                // log user out
                this.logout();
              }
            } else {
              // ok
              if (result.data.data.menu && result.data.data.menu.menuRoot) {
                // get updated
                let menuRootTemp = result.data.data.menu.menuRoot;
                if (menuRootTemp)
                  for (let i = 0; i < menuRootTemp.data.items.length; i++) {
                    if (!menuRootTemp.data.items[i].active) {
                      delete menuRootTemp.data.items[i];
                    }
                    if (menuRootTemp.data.items[i].data.items) {
                      for (
                          let j = 0;
                          j < menuRootTemp.data.items[i].data.items.length;
                          j++
                      ) {
                        if (!menuRootTemp.data.items[i].data.items[j]) {
                          delete menuRootTemp.data.items[i].data.items[j];
                        }
                      }
                    }
                  }
                console.log("menuroot: ", menuRootTemp);
                this.$session.set("menuRoot", menuRootTemp);
                this.menuRoot = menuRootTemp;
                console.log(result.data.data.menu.topupAccount);
                if (result.data.data.menu && result.data.data.menu.topupAccount) {
                  this.topupObject = result.data.data.menu.topupAccount;
                  this.$session.set(
                      "topupAccount",
                      result.data.data.menu.topupAccount
                  );
                } else {
                  console.log("top-up: ", this.$session.get("topupAccount"));
                  this.topupObject = this.$session.get("topupAccount");
                }
              } else {
                // take from cache
                this.menuRoot = this.$session.get("menuRoot");
                this.topupObject = this.$session.get("topupAccount");
              }
            }
          });
    },
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
  },
  computed: {},
  created: function () {
    this.checkLocal();
    // this.getMenuRoot();
    let self = this;
    setTimeout(() => {
      self.getProfilePic();
    }, 3000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.ant-dropdown-link {
  text-align: start;
}

.device {
  border: 1px solid darkgrey;
  border-radius: 45px;
  padding: 20px;
  margin: 10px;
}

.delete-button {
  text-align: end;

  button {
    padding: 7px;
    border-radius: 10px;
    border: none;
    background-color: grey;
    color: white;
  }
}

.whish-button {
  background-color: #e62946; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.margin-bot {
  margin-bottom: 3%;
}

nav {
  background-color: #e62946;

  router-link {
    color: white;
  }

  .dropdown {
    img {
      max-width: 40px;
    }

    width: 25%;
    text-align: start;
  }

  .profile {
    img {
      width: 75px;
      border-radius: 15px;
    }

    width: 25%;
    text-align: end;
  }

  .whish-logo {
    img {
      max-width: 111px;
    }

    width: 50%;
    text-align: center;
  }
}

#lol {
  text-align: center;
  padding: 5%;
}

.card_custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  padding: 2%;
  height: 100px;
}

.card_custom:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pointer {
  cursor: pointer;
}
</style>

<style lang="css" scoped>
.pulse {
  animation: pulse 3s infinite;
  margin: 0 auto;
  display: table;
  animation-direction: alternate;
  -webkit-animation: pulse 10s infinite;
  animation-name: pulse 10s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
